import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { UnknownObject } from "../../../../interfaces/global";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks/redux-hooks";
import { savePropositions } from "../../../../redux/thunks/politicians-ballot/politicians.thunks";
import PoliticianPage from "../../../politician/politician-profile/PoliticianPage";
import PropositionPage from "../../proposition/PropositionPage";
import FooterTabBar from "../../shared/FootTabBar";
import { clear_current_official } from "../../../../redux/slices/politicians/politicians.slice";

interface Param {
  place?: any;
  county?: any;
}

const Local = ({ place, county }: Param) => {
  const [state, setState] = useState<{ currentTab: string }>({
    currentTab: "Politicians",
  });

  const dispatch = useAppDispatch();
  const propositions = useAppSelector(store => store.politicians.propositions);
  const { politicians } = useOutletContext() as UnknownObject;
  const stateName = politicians?.data?.state?.name;

  if (politicians) {
    place = politicians?.data?.local?.place;
    county = politicians?.data?.local?.county;
  }

  useEffect(() => {
    // fetch props
    if (state.currentTab === "Proposition" && !propositions.jurisdiciton !== stateName) {
      dispatch(savePropositions(stateName));
    }
    dispatch(clear_current_official());
  }, [dispatch, propositions.jurisdiciton, stateName, state.currentTab]);
// }, [state, dispatch, propositions.jurisdiciton, place, stateName]);


  const offices = [...(place?.offices ?? []), ...(county?.offices ?? [])].sort(
    (office) => {
      if (office.name.toLowerCase().indexOf("mayor") !== -1) {
        return -1;
      }
      return 1;
    }
  );

  return (
    <>
      {state.currentTab === "Politicians" ? (
        <PoliticianPage offices={offices} />
      ) : (
        <PropositionPage data={propositions.data} jurisdiciton={place.name}/>
      )}
      <FooterTabBar
        leftLabel="Politicians"
        rightLabel="Proposition"
        onStateChange={(newState: string) => {
          setState({
            ...state,
            currentTab: newState
          });
        }}
      />
    </>
  );
};

export default Local;
