import { useState } from "react";
import PreviousAndButton from "../shared/previous-button/PreviousAndButton";
import { CommentWindow } from "../comments/comment-window/CommentWindow";
import CommentAndSupport from "../shared/comment-support/CommentAndSupport";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/redux-hooks";
import { UnknownObject } from "../../interfaces/global";
import { ENTITY_TYPE } from "../../constants/entities";
import { handleSupport } from "../../firebase/shared/handle-support";
import { Link, Outlet } from "react-router-dom";
import "./BillProfile.css";
import * as routes from '../../constants/routes';

interface Param {
  billId: string;
  billData: UnknownObject;
  jurisdiction?: string | null;
  backRoute?: string;
  localStateRefresh: Function;
}


const BillLayout = ({ billId, billData, jurisdiction = null, backRoute, localStateRefresh }: Param) => {
  const dispatch = useAppDispatch();

  const userAccount = useAppSelector(store => store.account);

  const { data } = billData || {};
  const { appData, entityData } = data || {};
  
  const { 
    propositionsSupporting: supporting, 
    propositionsOpposing: opposing 
  } = userAccount;
    
  const userSupportsBill = 
    supporting.includes(billId) ? true : 
    opposing.includes(billId) ? false : null;
  
  const entityInfo = { 
    id: billId, 
    type: ENTITY_TYPE['proposition'], 
  }

  const uniqueData = { jurisdiction: jurisdiction || '' } 

  const [ selected, updateSelected ] = useState("info");
  const [ commentWindow, toggleCommentWindow ] = useState(false);
  const [ newCommentAdded, trackCommentAdded ] = useState(false);

  const handleClick = (e: any) => {
    updateSelected(e.target.innerText.toLowerCase());
  }

  const onSupport = () => {
    const userSupports = supporting.includes(billId);
    handleSupport(
      { 
        entityInfo,
        entityData,
        uniqueData,
        userAccount, 
        doesSupport: !userSupports || null,
        localStateRefresh,
        dispatch
      }
    )
  }

  const onOppose = () => {
    const userSupports = opposing.includes(billId);
    handleSupport(
      { 
        entityInfo,
        entityData,
        uniqueData,
        userAccount,
        doesSupport: userSupports && null,
        localStateRefresh,
        dispatch
      }
    )
  }
  
  return (
    <div className="bill-profile-wrapper">
      <PreviousAndButton
        label="FOLLOW"
        route={backRoute}
      />
      <div className="bill-title-container flex-center">
        <div className="bill-id">
          Bill ID: {billId}
        </div>
        <div className="bill-title">{entityData?.title}</div>

        <CommentAndSupport
          userSupportsEntity={userSupportsBill}
          handleSupport={onSupport} 
          handleOppose={onOppose}
          openCommentWindow={() => toggleCommentWindow(true)}
          tickerData={{
            supporting: appData?.supporting.length || [],
            opposing: appData?.opposing.length || []
          }}
        />

        <div className="bill-navigation">
          <div
            id={`${selected === 'info' && 'selected-bill-nav'}`}>
              <Link
                onClick={handleClick}
                to={`/bill-profile/${billId}/info`}
                state={{ 
                  billId, entityData, backRoute
                }}
              >
                INFO
              </Link>
          </div>
          <div
            id={`${selected === 'comments' && 'selected-bill-nav'}`}>
            <Link
              onClick={handleClick}
              to={
                `/bill-profile/${billId}${routes.COMMENTS}`
              }
            >
              COMMENTS
            </Link>
          </div>
        </div>
      </div>
      <Outlet context={{ billData, entityData, entityInfo }} />
      { commentWindow ?
        <CommentWindow
          closeCommentWindow={() => toggleCommentWindow(false)}
          doesSupport={userSupportsBill}
          entityData={entityData}
          entityInfo={entityInfo}
          handleOppose={onOppose}
          handleSupport={onSupport}
          newCommentAdded={newCommentAdded}
          trackCommentAdded={trackCommentAdded}
          uniqueData={uniqueData}
        /> : null }
    </div>
  );
};

export default BillLayout;
