import { useState, useEffect } from "react";
import { UnknownObject } from "../interfaces/global";

const useOnce = (fn: Function | UnknownObject) => {
  const [isCalled, setIsCalled] = useState(false);
  const [result, updateResult] = useState(null);

  useEffect(() => {
    if (!isCalled) {
      setIsCalled(true);
      updateResult(
        fn instanceof Function ? fn() : fn
      );
    }
  }, [fn, isCalled, setIsCalled, result]);

  return result;
};

export default useOnce;
