import { useCallback, useEffect, useState } from "react";
import PreviousAndButton from "../../shared/previous-button/PreviousAndButton";
import { CommentWindow } from "../../comments/comment-window/CommentWindow";
import CommentAndSupport from "../../shared/comment-support/CommentAndSupport";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/redux-hooks";
import { UnknownObject } from "../../../interfaces/global";
import { ENTITY_TYPE } from "../../../constants/entities";
import { handleSupport } from "../../../firebase/shared/handle-support";
import { Link, Outlet } from "react-router-dom";
import "../../bill-profile/BillProfile.css";
import { getPropositionWithId } from "../../../firebase/account/update-account";

interface Param {
  propId: string;
  proposition: UnknownObject;
  jurisdiction?: string | null;
  backRoute?: string;
  updatePropTicker?: Function;
}

interface EntityData {
  appData: any;
  entityData?: any;
}

const PropsLayout = ({ propId, proposition, jurisdiction = null, backRoute, updatePropTicker }: Param) => {

  const dispatch = useAppDispatch();

  const userAccount = useAppSelector(store => store.account);

  const [curProp, setCurProp] = useState<EntityData>({ appData: { supporting: [], opposing: [] } });

  const { appData } = curProp;
  
  const { 
    propositionsSupporting: supporting, 
    propositionsOpposing: opposing 
  } = userAccount;

  const sponsorContent = proposition?.sponsorState
    ? `${proposition?.sponsorName} (${proposition?.sponsorParty} - ${proposition?.sponsorState}) - ${proposition?.sponsorTitle}`
    : `${proposition?.sponsorName} (${proposition?.sponsorParty})`;
  
  const propositionId = proposition?.billId || proposition?.id;
  
  const userSupportsBill = 
    supporting.includes(propositionId) ? true : 
    opposing.includes(propositionId) ? false : null;
  
  const entityInfo = { 
    id: propositionId, 
    type: ENTITY_TYPE['proposition'], 
  }

  const uniqueData = { jurisdiction: jurisdiction || '' } 

  const [ selected, updateSelected ] = useState("info");
  const [ commentWindow, toggleCommentWindow ] = useState(false);
  const [ newCommentAdded, trackCommentAdded ] = useState(false);

  const propInfoFromId = useCallback(async () => {
    const prop: EntityData = await getPropositionWithId(propId) as EntityData;
    setCurProp(prop);
  }, [propId])

  useEffect(() => {
    propInfoFromId();
  }, [propId, propInfoFromId]);
  
  const handleClick = (e: any) => {
    updateSelected(e.target.innerText.toLowerCase());
  }

  const onSupport = () => {
    const userSupports = supporting.includes(propositionId);
    handleSupport(
      { 
        entityInfo,
        entityData: proposition,
        uniqueData,
        userAccount, 
        doesSupport: !userSupports || null,
        localStateRefresh: propInfoFromId,
        dispatch
      }
    )
  }

  const onOppose = () => {
    const userSupports = opposing.includes(propositionId);
    handleSupport(
      { 
        entityInfo,
        entityData: proposition,
        uniqueData,
        userAccount,
        doesSupport: userSupports && null,
        localStateRefresh: propInfoFromId,
        dispatch
      }
    )
  }

  return (
    <div className="bill-profile-wrapper">
      <PreviousAndButton
        label="FOLLOW"
        route={backRoute}
      />
      <div className="bill-title-container flex-center">
        <div className="bill-id">
          Bill ID: {proposition?.id ?? proposition?.id}
        </div>
        <div className="bill-title">{proposition?.title}</div>

        <CommentAndSupport
          userSupportsEntity={userSupportsBill}
          handleSupport={onSupport} 
          handleOppose={onOppose}
          openCommentWindow={() => toggleCommentWindow(true)}
          tickerData={{
            supporting: appData?.supporting.length || [],
            opposing: appData?.opposing.length || []
          }}
        />

        <div className="bill-navigation">
          <div
            id={`${selected === 'info' && 'selected-bill-nav'}`}>
              <Link
                onClick={handleClick}
                state={{ 
                  isFeedComment: false, 
                  jurisdiction, 
                  proposition, 
                  backRoute 
                }}
                to={`/proposition-profile/${proposition?.billId ?? proposition?.id}/info`}
              >
                INFO
              </Link>
          </div>
          <div
            id={`${selected === 'comments' && 'selected-bill-nav'}`}>
            <Link
              onClick={handleClick}
              state={{ 
                isFeedComment: false, 
                jurisdiction, 
                proposition, 
                backRoute 
              }}
              to={
                `/proposition-profile/${proposition?.billId ?? proposition?.id}/comments/proposition/${proposition?.billId ?? proposition?.id}`
              }
            >
              COMMENTS
            </Link>
          </div>
        </div>
      </div>
      <Outlet context={{ sponsorContent, proposition, entityInfo }} />
      { commentWindow ?
        <CommentWindow
          closeCommentWindow={() => toggleCommentWindow(false)}
          doesSupport={userSupportsBill}
          entityData={proposition}
          entityInfo={entityInfo}
          handleOppose={onOppose}
          handleSupport={onSupport}
          newCommentAdded={newCommentAdded}
          trackCommentAdded={trackCommentAdded}
          uniqueData={uniqueData}
        /> : null }
    </div>
  );
};

export default PropsLayout;
