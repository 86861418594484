import { useOutletContext } from "react-router-dom";
import { UnknownObject } from "../../interfaces/global";
import BillProfileSection from "./BillProfileSection";
import Loader from "../shared/loading/loader";

interface BillInfoParam {
  billData: UnknownObject;
}

const BillInfo = () => {
  const { billData } = useOutletContext<BillInfoParam>();
  
  const { isLoading } = billData;

  if (isLoading) return <Loader isFull={true} />
  const { entityData } = billData?.data || {};
  const { members, dateIssued, currentChamber, originChamber } = entityData || {};
  
  const sponsored = (members || []).find((member: any) => member.role === 'SPONSOR');
  // use packageLink to make call to gov...
  return (
    <div>
      <BillProfileSection
        label="Sponsored By"
        content={sponsored?.memberName}
        showMore={false}
      />
      <BillProfileSection
        label="Current Chamber"
        content={currentChamber || "No Information"}
        showMore={false}
      />
       <BillProfileSection
        label="Origin Chamber"
        content={originChamber || "No Information"}
        showMore={false}
      />
      <BillProfileSection
        label="Date Introduced"
        content={dateIssued}
        showMore={false}
      />
    </div>
  )
}

export default BillInfo;