import { useEffect, useState } from "react";
import { resolveOfficialFromData } from "../util/util.politician";
import PersonProfileLayout from "../../shared-layout/PersonProfileLayout";
import { fetchDefaultImage } from "../../../api/api.wikipedia";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks/redux-hooks";
import { useMatches, useLocation } from "react-router-dom";
import { savePoliticianData } from "../../../redux/thunks/politicians-ballot/politicians.thunks";
import { savePoliticianFollowing } from "../../../redux/thunks/account/account.action-creators";
import { updateInterests } from "../../../firebase/account/update-account";
import { addNewPoliticianToDb, updatePoliticianFollowers } from "../../../firebase/politician-ballot/update-politician";
import { FoundOfficial, OfficialFromDb } from "../../../interfaces/politicians.interfaces";
import { fetchPoliticianTickerData } from "../../../firebase/politician-ballot/fetch-politicians";
import useOnce from "../../../hooks/useOnce";
import { update_current_official } from "../../../redux/slices/politicians/politicians.slice";

const tempLocation = {
  previousLocation: ''
}

const PoliticianProfile = () => {

  const store = useAppSelector(store => store);

  const dispatch = useAppDispatch();

  const location = useOnce(useLocation());

  const { previousLocation } = (location as any)?.state || tempLocation;

  const { politicians, account } = store;
  const { currentOfficial } = politicians;
  const { userId, politiciansFollowing } = account;

  // const [currentOfficial, updateCurrentOfficial] = 
  //   useState<OfficialWithDB>(tempOfficialData as OfficialWithDB);

  const [defaultImage, setDefaultImage] = useState(null);
  const officialName = useMatches()[0].params.officialName || "";
  const [isFollowed, setIsFollowed] = useState(politiciansFollowing?.includes(officialName));
  
  useEffect(() => {
    if (!currentOfficial.entityData.name) {
      // Right here we are checking to see if there is no data within redux for politicians 
      // Ideally, we should have this populated from the list we generated previously
      if (politicians.data == null) {
        dispatch(savePoliticianData());
      }

      if (officialName) {
        const savePoliticianDataFunc = async () => {
          const officialFromDb: unknown | OfficialFromDb = await fetchPoliticianTickerData(officialName);
          if ((officialFromDb as OfficialFromDb)?.appData) {
            dispatch(update_current_official(officialFromDb));
          } else {
            const image = await fetchDefaultImage(officialName);
            setDefaultImage(image);

            const official: FoundOfficial | null = resolveOfficialFromData({
              politicians,
              officialName,
              otherKeyValues: [{ key: 'photoURL', value: image }]
            });

            if (official) {
              dispatch(update_current_official({ entityData: official, appData: null }));
            }
          }
        }
        savePoliticianDataFunc();
      }
    }
  }, [account.userId, currentOfficial, officialName, dispatch, politicians]);

  const tabData = {
    tabs:[
      { label: "bio", link: `/politician-profile/${officialName}` },
      { label: "news", link: `/politician-profile/${officialName}/news` },
      { label: "comments", link: `/politician-profile/${officialName}/comments/politician/${officialName}` },
    ],
    preSelected: 'bio'
  };

  const updatePoliticianData = async () => {
    const officialFromDb: unknown | OfficialFromDb = await fetchPoliticianTickerData(officialName);
    if ((officialFromDb as OfficialFromDb)?.appData) {
      dispatch(update_current_official(officialFromDb));
    }
  }

  // TO DO: Needs to be refactored
  const followHandler = async (official: any) => {
    const { name } = official.entityData;

    const isFollowed =
      politiciansFollowing?.includes(name);

    setIsFollowed(!isFollowed);

    // Update entity if no appData
    if (!official.appData) {
      await addNewPoliticianToDb(official);
    }

    await updateInterests({ account, interest: officialName });
    await updatePoliticianFollowers({ userId, politician: name, isFollowed });
    await savePoliticianFollowing({ name, isFollowed, userId, dispatch });
    // We want to update the politician doc...
    await updatePoliticianData();
  }

  // Route back should take the first route that carried over from politician...
  // Can we get the last history item here?
  return (
    <>
      { currentOfficial.entityData ? 
        <PersonProfileLayout
          navigationAction={{
            label: isFollowed ? "FOLLOWING" : "FOLLOW",
            onClickButton: () => followHandler(currentOfficial),
            style: isFollowed && { opacity: ".8" },
            routeBack: previousLocation
          }}
          localStateRefresh={updatePoliticianData}
          currentOfficial={currentOfficial}
          fullname={officialName}
          photoURL={defaultImage}
          isPolitician={true}
          tabData={tabData}
        /> : null
      }
    </> 
  );
}

export default PoliticianProfile;
