import { auth, functions } from "../firebase/instance";
import { getRepresentativesUrl } from "../config";
import { parseRepresentativesResponse } from "./util/util.civic-api";
import { OPENSTATE_URI, OPENSTATE_API_KEY } from "../config";
import { usersCollectionRef } from "../firebase/instance";
import { doc, getDoc } from "firebase/firestore";
import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import { httpsCallable } from "firebase/functions";

export const actions = {
  FETCH_DATA: "fetch_data",
  FETCH_BILL: "fetch_bill",
  FETCH_PROPOSITION: "fetch_proposition",
};

export const fetchPoliticianData = async () => {
  const userId = auth?.currentUser?.uid;
  try {
    const userDoc = await getDoc(doc(usersCollectionRef, userId));
    
    if (userDoc.exists()) {
      const zipcode = userDoc.data().zipcode;
      const zip = parseInt(zipcode);
      const res = await fetch(getRepresentativesUrl({ zip }));

      if (res && typeof res.json === 'function') {
        const json = await res.json();
        const parsedData = parseRepresentativesResponse(json);
        return parsedData;
      }
    }
  } catch (err) {
    console.error("Error in politician data fetch: " + err);
  }
  return null;
};

export const fetchBills = async () => {

  const fetchNews = httpsCallable(functions, "fetchCongress");

  function getCurrentTimestamp(minus = 0) {
    const date = new Date();
    
    // Specify the options for the DateTimeFormat
    const options = {
      year: 'numeric' as const,
      month: '2-digit' as const,
      day: '2-digit' as const,
      timeZone: 'America/New_York' // Eastern Time
    };

    // Create a DateTimeFormat object with the specified options
    const formatter = new Intl.DateTimeFormat('en-US', options);

    // Format the date and extract parts
    const [
      { value: month },,
      { value: day },,
      { value: year }
    ] = formatter.formatToParts(date);

    return `${year}-${month}-${parseInt(day) + minus}T00:00:00Z`;
  };

  let res: any = [];
  let i = 0;

  while (i > -3) {
    const isoDate = getCurrentTimestamp(i);
    const tempRes = await fetchNews({ isoDate });
    if ((tempRes as unknown as any)?.data?.count > 0) {
      res.push(...(tempRes as unknown as any).data.packages);
      if (res.length > 5) {
        i = -3;
      }
    }
    i--;
  }

  return res;
}

export const fetchBillSummary = async (packageLink: string) => {
  const fetchSummary = httpsCallable(functions, "fetchBillSummary");

  const summary = await fetchSummary({ packageLink });

  return summary;
}

export const fetchProposition = async (name: string) => {

  const client = new ApolloClient({
    uri: OPENSTATE_URI,
    cache: new InMemoryCache(),
    headers: {
      "x-api-key": OPENSTATE_API_KEY,
    },
  });

  const bills = client
    .query({
      query: gql`
        query StateBillsQuery($jurisdiction: String!) {
          bills(
            first: 20
            jurisdiction: $jurisdiction
            updatedSince: "2019-01-01 00:00"
          ) {
            edges {
              node {
                id
                sponsorships {
                  name
                  entityType
                  organization {
                    name
                  }
                  person {
                    name
                    sortName
                    familyName
                    givenName
                    primaryParty
                  }
                }
                title
                updatedAt
                createdAt
                sources {
                  url
                }
                actions {
                  date
                  description
                  classification
                }
              }
            }
          }
        }
      `,
      variables: {
        jurisdiction: name,
      },
    })
    .then((result) => {
      if (result.data.bills.edges) {
        const bills = result.data.bills.edges;
        const parsedData = bills.map((bill: any) => {
          return {
            title: bill.node.title,
            name: new Date(bill.node.updatedAt).getFullYear(),
            status: "",
            url: bill.node.sources[0].url,
            id: bill.node.id.replace("/", "-"),
            sponsorName: bill.node.sponsorships[0]?.person?.name,
            sponsorParty: bill.node.sponsorships[0]?.person?.primaryParty,
            actions: bill.node.actions,
          };
        });
        return parsedData
      }
    })
    .catch((err) => console.error(err));
  console.log({ bills })
  return bills;
}
